<template>
    <el-dialog
        title="新增店铺补货申请"
        width="91%"
        top="3vh"
        :close-on-click-modal="true"
        :visible.sync="dialogVisible"
        append-to-body
        @close="close"
    >
        <div class="dialog-box">
            <div class="sub-box" @click="submitOn">
                <svg-icon style="font-size: 23px; margin-right: 10px" class-name="size-icon" icon-class="submit" />
                确认提交
            </div>
            <div class="dialog-top"></div>
            <div class="dialog-top2">
                <div class="top-box" v-for="item in topList" :key="item.type" @click="getItems(item.type)">
                    {{ item.text }}
                </div>
                <!-- <div class="top-box">本店想调出款</div>
                <div class="top-box">货品建议或备注</div> -->
            </div>
            <div class="bottom-box" v-show="itemsType == '1' || itemsType == '2'">
                <div class="left-box">
                    <div style="display: flex; margin-bottom: 20px">
                        <erp-selector
                            style="width: 270px; height: 35px"
                            type="goodsNo"
                            v-model="spuInfoIdList"
                            ref="goodsItemNo"
                            modelKey="spu"
                            placeholder="请选择货号"
                            :multiple="true"
                        ></erp-selector>
                        <div class="start-box" @click="search">
                            <span>查 询</span>
                        </div>
                    </div>
                    <div v-show="itemsType == '1'">
                        <grid-manager :option="gridOptionOne"></grid-manager>
                    </div>
                    <div v-show="itemsType == '2'">
                        <grid-manager :option="gridOptionTow"></grid-manager>
                    </div>
                    <section
                        style="
                            display: flex;
                            align-items: center;
                            justify-content: end;
                            border: 1px solid #eee;
                            border-top: none;
                            height: 6vh;
                            padding: 0 10px;
                        "
                    >
                        <!-- <div>
                            <el-checkbox v-model="allSpuList" @change="selectAll">跨页全选 </el-checkbox>
                        </div> -->
                        <div class="btn-class">
                            <el-button type="success" @click="addList"
                                ><svg-icon icon-class="submit" class="icon-class" />确认添加</el-button
                            >
                        </div>
                    </section>
                </div>
                <div class="right-box">
                    <div class="top-item">
                        <!-- <span style="margin-right: 10px">搜索货号</span>
                        <div style="width: 200px">
                            <el-input clearable placeholder="请输入货号" size="small" v-model="goodsNoList"></el-input>
                        </div>
                        <div class="start-box" @click="search">
                            <span>查 询</span>
                        </div> -->
                        <div class="clearAll-box" @click="clearAll">
                            <svg-icon class-name="size-icon" icon-class="clear" style="font-size: 16px" /> 一键清空
                        </div>
                    </div>
                    <div class="right-top" :style="{ width: tableWidth }">
                        已添加货品明细
                        <span style="display: block; float: right; margin-right: 20px"
                            >共
                            {{ itemsType == '1' ? addedList.length : itemsType == '2' ? bddedList.length : '' }}
                            条</span
                        >
                    </div>
                    <div ref="tableBox">
                        <PuhuoTable
                            v-if="itemsType == '1'"
                            :numType="itemsType"
                            @deleItems="deleItems"
                            @getNum="getInNum"
                            @bigNot="bigNot"
                            @bigNot2="bigNot2"
                            style="width: 100%"
                            ref="puhuotaa"
                            sizeGroupType="2"
                            sizeGroupWidth="620px"
                            calcDivNum="4"
                            outWidth="974"
                            :headerLeft="headerLeft"
                            :addedList="addedList"
                            :isAdd="false"
                            :headerRight="headerRight"
                            v-loading="loading"
                        ></PuhuoTable>
                        <PuhuoTable
                            v-else-if="itemsType == '2'"
                            :numType="itemsType"
                            @deleItems="deleItems"
                            @getNum="getOutNum"
                            @bigNot="bigNot"
                            @bigNot2="bigNot2"
                            style="width: 100%"
                            ref="puhuotab"
                            sizeGroupType="2"
                            sizeGroupWidth="620px"
                            calcDivNum="4"
                            outWidth="974"
                            :headerLeft="headerLeft"
                            :addedList="bddedList"
                            :isAdd="false"
                            :headerRight="headerRight"
                            v-loading="loading"
                        ></PuhuoTable>
                    </div>
                </div>
            </div>
            <div class="bottom-box2" v-show="itemsType == '3'">
                <el-input
                    type="textarea"
                    :autosize="{ minRows: 4, maxRows: 6 }"
                    placeholder="请输入内容"
                    v-model="remark"
                >
                </el-input>
            </div>
        </div>
    </el-dialog>
</template>

<script>
import erpSelector from '@/components/ErpSelector/index'
import svgIcon from '@/components/SvgIcon/index'
import PuhuoTable from './PuhuoTable.vue'
import { erp2MarketReplenishment, erp2MarketQueryUnitGoodsSize, erp2MarketReplenishmentSubmit } from '@/libs/http/api'
export default {
    name: 'storeDialog',
    components: {
        PuhuoTable,
        erpSelector,
        svgIcon
    },

    data() {
        return {
            loading: false,
            spuInfoIdListA: [],
            spuInfoIdListB: [],
            tableWidth: '',
            addType: true,
            bddType: true,
            inNumList: [],
            outNumList: [],
            spuIdList: [],
            allIs: false,
            allSpuList: false,
            remark: '',
            spuInfoIdList: [],
            goodsNoList: '',
            dialogVisible: true,
            addedList: [],
            bddedList: [],
            headerLeft: [
                { key: 'idNo', name: '序号' },
                { key: 'goodsNo', name: '货号' },
                { key: 'goodsName', name: '货品名称' },
                { key: 'categoryName', name: '款式分类三级' },
                { key: 'retailPrice', name: '零售价' },
                { key: 'size', name: '码段' }
            ],
            headerRight: [
                { key: 'allNum', name: '小计' },
                { key: 'thisWeekSellNum', name: '本周售数' },
                { key: 'lastWeekSellNum', name: '上周售数' },
                { key: 'takeIndex', name: '操作' }
            ],
            gridOptionOne: {
                gridManagerName: 'storeDialogOne',
                firstLoading: true,
                isCombSorting: false,
                supportCheckbox: false,
                supportAutoOrder: false,
                useTrFocus: true,
                lineHeight: '30px',
                supportCheckbox: true,
                checkboxConfig: {
                    useRowCheck: true,
                    fixed: 'left'
                },
                checkedAfter: this.checkRows,
                pageSize: 100,
                sizeData: [100, 200, 500, 1000],
                bottomTip: {
                    display: false
                },
                ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                    <!--每页显示条数-->
                    <div class="change-size">
                        {{ vm.pageSizeOptionTpl }}
                    </div>
                    <!--分页切换-->
                    <div class="pagination">
                        <!--上一页、首页区-->
                        <ul pagination-before>
                            <li class="previous-page">
                                {{ vm.previousPageText }}
                            </li>
                        </ul>
                        <!--下一页、尾页区-->
                        <ul pagination-after>
                            <li class="next-page">
                                {{ vm.nextPageText }}
                            </li>
                        </ul>
                    </div>
                </div>`,
                columnData: [
                    { key: 'goodsNo', text: '货号', width: '120' },
                    { key: 'goodsName', text: '货号名称' },
                    { key: 'categoryName', text: '款式分类', width: '120' }
                ],
                ajaxData: this.fetch, //数据
                dataKey: 'items',
                totalsKey: 'totalItem',
                supportAjaxPage: true,
                height: '65vh',
                cellDblClick: this.rowdbClick,
                deptList: []
                // userId: this.$cache.session.get(this.$macro.cache.KEY_USER_ID)
            },
            gridOptionTow: {
                gridManagerName: 'storeDialogTow',
                firstLoading: true,
                isCombSorting: false,
                supportAutoOrder: false,
                useTrFocus: true,
                lineHeight: '30px',
                supportCheckbox: true,
                checkboxConfig: {
                    useRowCheck: true,
                    fixed: 'left'
                },
                checkedAfter: this.checkRows,
                pageSize: 100,
                sizeData: [100, 200, 500, 1000],
                bottomTip: {
                    display: false
                },
                ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                    <!--每页显示条数-->
                    <div class="change-size">
                        {{ vm.pageSizeOptionTpl }}
                    </div>
                    <!--分页切换-->
                    <div class="pagination">
                        <!--上一页、首页区-->
                        <ul pagination-before>
                            <li class="previous-page">
                                {{ vm.previousPageText }}
                            </li>
                        </ul>
                        <!--下一页、尾页区-->
                        <ul pagination-after>
                            <li class="next-page">
                                {{ vm.nextPageText }}
                            </li>
                        </ul>
                    </div>
                </div>`,
                columnData: [
                    { key: 'goodsNo', text: '货号', width: '120' },
                    { key: 'goodsName', text: '货号名称', width: '120' },
                    { key: 'notSentQuantity', text: '已配未发', width: '90' },
                    { key: 'intransitQuantity', text: '在途', width: '90' }
                ],
                ajaxData: this.fetch, //数据
                dataKey: 'items',
                totalsKey: 'totalItem',
                supportAjaxPage: true,
                height: '65vh',
                cellDblClick: this.rowdbClick,
                deptList: []
                // userId: this.$cache.session.get(this.$macro.cache.KEY_USER_ID)
            },
            topList: [
                { text: '本店想补充款', type: '1' },
                { text: '本店想调出款', type: '2' },
                { text: '货品建议或备注', type: '3' }
            ],
            itemsType: '1'
        }
    },
    watch: {
        spuInfoIdList() {
            if (this.itemsType == '1') {
                this.spuInfoIdListA = this.spuInfoIdList
                GridManager.refreshGrid(this.gridOptionOne.gridManagerName)
            } else if (this.itemsType == '2') {
                this.spuInfoIdListB = this.spuInfoIdList
                GridManager.refreshGrid(this.gridOptionTow.gridManagerName)
            }
        },
        addedList() {
            if (this.addedList.length > 5) {
                this.addedList.pop()
                return this.$message.error('最多只能选择5条数据!')
            }
        },
        bddedList() {
            if (this.bddedList.length > 5) {
                this.bddedList.pop()
                return this.$message.error('最多只能选择5条数据!')
            }
        }
    },
    created() {
        this.setTableWidth()
        this.$nextTick(() => {
            document.querySelectorAll('.top-box').forEach((item, index) => {
                if (this.itemsType == Number(index) + 1) {
                    document.querySelectorAll('.top-box')[index].style.background = '#FFF'
                    document.querySelectorAll('.top-box')[index].style.color = '#067cf2'
                    document.querySelectorAll('.top-box')[index].style.borderBottom = 'none'
                }
            })
        })
    },
    methods: {
        setTableWidth() {
            this.$nextTick(() => {
                this.tableWidth = this.$refs.tableBox.offsetWidth - 4 + 'px'
            })
        },
        bigNot(type) {
            this.addType = type
        },
        bigNot2(type) {
            this.bddType = type
        },
        submitOn() {
            if (this.itemsType == '1') {
                this.getInNum()
            } else if (this.itemsType == '2') {
                this.getOutNum()
            }
            if (this.addedList.length) {
                if (this.inNumList.length) {
                    this.goSubmit()
                } else {
                    this.$message.error('请在需要补充款货品填写数量！')
                }
            } else if (this.bddedList.length) {
                let bisNum = null
                let bisNumList = this.bddedList.filter((item) => {
                    item.isNum = item.sizeInfoList.some((a) => {
                        return a.usableQuantity !== '0'
                    })
                    if (!bisNum) {
                        bisNum = item.sizeInfoList.every((b) => {
                            return b.usableQuantity == '0'
                        })
                    }
                    return item.isNum
                })
                this.bddedList = bisNumList
                if (this.bddedList.length) {
                    this.$confirm('在所选货号中有存在库存为0的货品，是否继续提交？', '确认提交')
                        .then(() => {
                            this.goSubmit()
                        })
                        .catch(() => {})
                } else {
                    this.$message({
                        type: 'error',
                        message: `未选中货品或所选货号库存不足 !`,
                        duration: 2000,
                        showClose: true
                    })
                }
            } else {
                this.$message.error('请先添加要补充款或调出款！')
            }
        },
        goSubmit() {
            erp2MarketReplenishmentSubmit({
                remark: this.remark,
                calloutInfoList: this.outNumList,
                repairInfoList: this.inNumList
            })
                .then((data) => {
                    this.$message.success('添加成功')
                    this.close(true)
                })
                .catch((error) => {
                    this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
                })
        },
        deleItems(deleData) {
            if (deleData.type == '1') {
                this.addedList.splice(deleData.index, 1)
                this.addType = deleData.boolean
            } else if (deleData.type == '2') {
                this.bddedList.splice(deleData.index, 1)
                this.bddType = deleData.boolean
            }
        },
        addList(row) {
            let type = null
            if (this.itemsType == '1') {
                type = this.addType
            } else if (this.itemsType == '2') {
                type = this.bddType
            }
            if (type) {
                let reqData = null
                const list =
                    this.itemsType == '1'
                        ? GridManager.getCheckedData(this.gridOptionOne.gridManagerName).map((item) => {
                              return item.spuInfoId
                          })
                        : GridManager.getCheckedData(this.gridOptionTow.gridManagerName).map((item) => {
                              return item.spuInfoId
                          })
                if (row && row.spuInfoId) {
                    if (this.itemsType == '2' && (row.intransitQuantity !== '0' || row.notSentQuantity !== '0')) {
                        this.$message.error('只能添加在途库存和已配未发库存为0的货号!')
                        return
                    } else {
                        reqData = row.spuInfoId.split(' ')
                    }
                } else {
                    if (list.length) {
                        let numIf = null
                        if (this.itemsType == '2') {
                            const array = GridManager.getCheckedData(this.gridOptionTow.gridManagerName).map((item) => {
                                return {
                                    notSentQuantity: item.notSentQuantity,
                                    intransitQuantity: item.intransitQuantity
                                }
                            })
                            array.forEach((item) => {
                                if (item.notSentQuantity !== '0' || item.notSentQuantity !== '0') {
                                    numIf = false
                                }
                            })
                        }
                        if (this.itemsType == '2' && numIf == false) {
                            return this.$message.error('只能添加在途库存和已配未发库存为0的货号!')
                        } else {
                            reqData = list
                            GridManager.setCheckedData(this.gridOptionOne.gridManagerName, [])
                            GridManager.setCheckedData(this.gridOptionTow.gridManagerName, [])
                        }
                    } else {
                        return this.$message.error('请先勾选货品！')
                    }
                }
                this.loading = true
                erp2MarketQueryUnitGoodsSize({
                    spuIdList: reqData
                })
                    .then((data) => {
                        if (this.itemsType == '1') {
                            this.addedList = this.repeatDataList(this.addedList.concat(data.goodsInfoRespList))
                            this.loading = false
                        } else if (this.itemsType == '2') {
                            this.bddedList = this.repeatDataList(this.bddedList.concat(data.goodsInfoRespList))
                            this.loading = false
                            // let bisNum = null
                            // let bisNumList = this.bddedList.filter((item) => {
                            //     item.isNum = item.sizeInfoList.some((a) => {
                            //        return a.usableQuantity !== '0'
                            //     })
                            //     if (!bisNum) {
                            //         bisNum = item.sizeInfoList.every((b) => {
                            //             return b.usableQuantity == '0'
                            //         })
                            //     }
                            //     return item.isNum
                            // })
                            // if (bisNum) {
                            //     this.$message({ type: "error", message: `在所选货号中有存在预计库存不足的货品，无法选中 !`, duration: 2000, showClose: true })
                            // }
                            // this.bddedList = bisNumList
                        }
                    })
                    .catch((error) => {
                        this.loading = false
                        this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
                    })
            } else {
                return this.$message.error('最多只能添加5条数据!')
            }
        },
        //添加去重
        repeatDataList(array) {
            const result = []
            const seen = new Set()

            for (const obj of array) {
                // 将对象转换为字符串作为判断重复的依据
                const objString = JSON.stringify(obj)

                if (!seen.has(objString)) {
                    result.push(obj)
                    seen.add(objString)
                }
            }

            return result
        },
        selectAll(e) {
            if (e) {
                this.allDataList()
            } else {
                GridManager.setCheckedData(this.gridOptionOne.gridManagerName, [])
                GridManager.setCheckedData(this.gridOptionTow.gridManagerName, [])
            }
        },
        allDataList() {
            erp2MarketReplenishment({
                type: this.itemsType,
                spuIdList: this.spuInfoIdList,
                goodsNoList: this.goodsNoList.split(' ')
            })
                .then((data) => {
                    GridManager.setCheckedData(this.gridOptionOne.gridManagerName, data.items)
                    GridManager.setCheckedData(this.gridOptionTow.gridManagerName, data.items)
                })
                .catch((error) => {
                    this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
                })
        },
        checkRows(a) {
            if (a.length < 6) {
                this.spuIdList = a.map((item) => {
                    return item.spuInfoId
                })
            } else {
                GridManager.setCheckedData(
                    this.itemsType == '1' ? this.gridOptionOne.gridManagerName : this.gridOptionTow.gridManagerName,
                    a.slice(0, 5)
                )
                return this.$message.error('最多可添加5条数据!')
            }
        },
        clearAll() {
            this.$confirm('此操作将会清空添加记录', '提示')
                .then(() => {
                    this.itemsType == '1' ? this.$refs.puhuotaa.clearAll() : this.$refs.puhuotab.clearAll()
                    this.itemsType == '1'
                        ? GridManager.setCheckedData(this.gridOptionOne.gridManagerName, [])
                        : GridManager.setCheckedData(this.gridOptionTow.gridManagerName, [])
                    if (this.itemsType == '1') {
                        this.addedList = []
                        this.addType = true
                    } else if (this.itemsType == '2') {
                        this.bddedList = []
                        this.bddType = true
                    }
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500
                    })
                })
                .catch(() => {})
        },
        getItems(type) {
            this.itemsType = type
            document.querySelectorAll('.top-box').forEach((item, index) => {
                item.style.background = '#f2f2f2'
                item.style.color = '#606266'
                item.style.borderBottom = '1px solid #f1f2f4'
                if (type == Number(index) + 1) {
                    document.querySelectorAll('.top-box')[index].style.background = '#FFF'
                    document.querySelectorAll('.top-box')[index].style.color = '#067cf2'
                    document.querySelectorAll('.top-box')[index].style.borderBottom = 'none'
                }
            })
            if (this.itemsType == '1') {
                this.spuInfoIdList = this.spuInfoIdListA
            } else if (this.itemsType == '2') {
                this.spuInfoIdList = this.spuInfoIdListB
            }
        },
        getInNum() {
            this.inNumList = []
            const repairInfoList = this.$refs.puhuotaa.dataList.map((item) => {
                return item.sizeInfoList.map((a) => {
                    if (a.number > 0) {
                        return {
                            spu: item.spu,
                            sku: a.sku,
                            num: a.number
                        }
                    }
                })
            })
            repairInfoList.forEach((y) => {
                y.forEach((h) => {
                    if (h) {
                        this.inNumList.push(h)
                    }
                })
            })
        },
        getOutNum() {
            this.outNumList = []
            const callOutList = this.$refs.puhuotab.dataList.map((item) => {
                return item.sizeInfoList.map((a) => {
                    if (a.usableQuantity != 0) {
                        return {
                            spu: item.spu,
                            sku: a.sku,
                            num: a.usableQuantity
                        }
                    }
                })
            })
            callOutList.forEach((y) => {
                y.forEach((h) => {
                    if (h) {
                        this.outNumList.push(h)
                    }
                })
            })
        },
        search() {
            GridManager.refreshGrid(this.gridOptionOne.gridManagerName)
        },
        close(closeType) {
            this.dialogVisible = true
            if (closeType) {
                this.dialogVisible = false
                this.$emit('close')
            } else {
                this.$confirm('确认关闭？', '提示')
                    .then(() => {
                        this.dialogVisible = false
                        this.$emit('close')
                    })
                    .catch(() => {
                        this.dialogVisible = true
                    })
            }
        },
        fetch(params) {
            console.log(params, '/////////////////////')
            let reqData = {
                type: this.itemsType,
                page: params ? params.pageData.cPage : '1',
                pageSize: params ? params.pageData.pSize : '100'
            }
            if (this.spuInfoIdList.length > 0) {
                reqData['spuList'] = this.spuInfoIdList
            }
            if (this.goodsNoList !== '') {
                reqData['goodsNoList'] = this.goodsNoList.split(' ')
            }
            return erp2MarketReplenishment(reqData)
        },
        rowdbClick(row) {
            this.addList(row)
        }
    }
}
</script>

<style lang="scss" scoped>
.dialog-box {
    width: 88vw;
    height: 84vh;
    .dialog-top {
        height: 2px;
        width: 100%;
        margin-top: -10px;
        background: #dbdbdb;
    }
    .dialog-top2 {
        width: 96%;
        height: 40px;
        margin: 20px auto;
        border-bottom: 2px solid #f1f2f4;
        display: flex;
        .top-box {
            cursor: pointer;
            height: 40px;
            padding: 10px 30px;
            margin-right: 5px;
            background: #f2f2f2;
            border: 1px solid #f1f2f4;
        }
    }
    .bottom-box {
        width: 96%;
        height: 82%;
        margin: 0 auto;
        display: flex;
        .left-box {
            width: 27%;
            height: 100%;
            margin-right: 20px;
        }
        .right-box {
            flex: 1;
            height: 100%;
            .top-item {
                display: flex;
                align-items: center;
                margin-bottom: 20px;
            }
            .right-top {
                height: 50px;
                line-height: 50px;
                text-align: center;
                background: #f2f2f2;
            }
        }
    }
    .bottom-box2 {
        width: 97%;
        height: 82%;
        margin: 0 auto;
    }
}
.sub-box {
    color: black;
    width: 170px;
    height: 40px;
    background: #c6e7ff;
    cursor: pointer;
    border-radius: 3px;
    position: absolute;
    top: 10px;
    right: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.start-box {
    height: 33px;
    line-height: 33px;
    margin-left: 15px;
    cursor: pointer;
    padding: 0px 17px;
    font-size: 14px;
    background: #c6e7ff;
    border-radius: 3px;
}
.clearAll-box {
    height: 35px;
    margin-left: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 20px;
    background: #c6e7ff;
    font-size: 12px;
    cursor: pointer;
    border-radius: 3px;
}
.start-box:active {
    color: #067cf2;
}

.el-input input {
    height: 32px !important;
    // 解决客户端错位问题
    position: relative;
    top: -1px;
}
</style>
