var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"table"},[_c('section',{ref:"head",staticClass:"table-header"},[_vm._l((_vm.headerLeft),function(item){return _c('div',{key:item.key,style:({
                height: _vm.sizeGroup.length > 0 ? _vm.sizeGroup.length * 35 + 'px' : 35 + 'px',
                lineHeight: _vm.sizeGroup.length > 0 ? _vm.sizeGroup.length * 37 + 'px' : 37 + 'px',
                width: _vm.returnWidth(item)
            })},[_c('span',[_vm._v(_vm._s(item.name))])])}),(_vm.valueGroupIdList.length <= 0)?[_c('section',{staticClass:"size-group",staticStyle:{"width":"440px","height":"30px"}},_vm._l((1),function(item){return _c('div',{key:item,staticStyle:{"text-align":"center","line-height":"23px"}},[_vm._v("尺码组")])}),0)]:[_c('div',{staticClass:"size-group",staticStyle:{"width":"440px","display":"flex","flex-direction":"column","justify-content":"center"}},_vm._l((_vm.sizeGroup),function(item){return _c('div',{key:item.valueGroupId,class:[_vm.currentGroup == item.valueGroupId ? 'current-group' : '']},_vm._l((item.valueInfoList),function(size,index){return _c('span',{key:index},[_vm._v(_vm._s(size.value))])}),0)}),0)],_vm._l((_vm.headerRight),function(item){return _c('div',{key:item.key,style:({
                height: _vm.sizeGroup.length > 0 ? _vm.sizeGroup.length * 35 + 'px' : 35 + 'px',
                lineHeight: _vm.sizeGroup.length > 0 ? _vm.sizeGroup.length * 37 + 'px' : 37 + 'px',
                width: _vm.componentType ? 93.4 + 'px' : 60 + 'px'
            })},[_c('span',[_vm._v(_vm._s(item.name))])])})],2),(_vm.dataList.length != 0)?_c('section',{ref:"body",staticClass:"table-body sizeInfo",style:({
            width: _vm.componentType ? 1250 + 'px' : _vm.tableWidth
        })},[_vm._l((_vm.dataList),function(item,index){return [_c('div',{key:index,class:['data-row', index == _vm.rowIndex ? 'current-row' : ''],on:{"click":function($event){_vm.currentGroup = item.valueGroupId
                    _vm.rowIndex = index}}},[[_c('div',{staticStyle:{"width":"55px"}},[_c('span',[_vm._v(_vm._s(index + 1))])]),_c('div',{staticStyle:{"width":"104.5px"}},[_c('span',[_vm._v(_vm._s(item.goodsNo))])]),_c('div',{staticStyle:{"width":"104.5px"}},[_c('span',[_vm._v(_vm._s(item.goodsName))])]),_c('div',{staticStyle:{"width":"104.5px"}},[_c('span',[_vm._v(_vm._s(item.categoryName))])]),_c('div',{staticStyle:{"width":"55px"}},[_c('span',[_vm._v(_vm._s(item.retailPrice))])]),_c('div',{staticClass:"num-box",staticStyle:{"width":"104.5px"}},[_c('div',[_vm._v("预计库存数")]),(_vm.numType == '1')?_c('div',{staticStyle:{"border-top":"1px solid #e6ebf5"}},[_vm._v("想补单数")]):_vm._e()])],_c('section',{staticClass:"size-group",staticStyle:{"width":"440px"}},_vm._l((_vm.getSizeInfoList(item, index)),function(size,s){return _c('div',{key:s},[_c('div',{staticClass:"num-box",staticStyle:{"width":"35px","height":"50px","line-height":"50px","border-right":"1px solid #e6ebf5"},style:({ background: _vm.getNumberItem(size, item) == '0' ? '#e6ebf5' : '#FFF' })},[_c('div',{style:({
                                    height: _vm.numType == '1' ? '24px' : '50px',
                                    lineHeight: _vm.numType == '1' ? '24px' : '50px',
                                    background: _vm.getUsableQuantity(size, item) == '0' ? '#e6ebf5' : '#FFF'
                                })},[_vm._v(" "+_vm._s(_vm.getUsableQuantity(size, item) == '0' ? '' : _vm.getUsableQuantity(size, item))+" ")]),(
                                    _vm.numType == '1' &&
                                    !_vm.componentType &&
                                    (size.number == '0' || size.number > '0' || size.number == '')
                                )?_c('div',{style:({
                                    height: _vm.numType == '1' ? '24px' : '50px',
                                    lineHeight: _vm.numType == '1' ? '24px' : '50px',
                                    borderTop: '1px solid #e6ebf5'
                                })},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(size.number),expression:"size.number"}],style:({
                                        border: 'none',
                                        width: '100%',
                                        height: '100%',
                                        background: size.number > 0 && !_vm.componentType ? '#43d268' : '#facd91',
                                        outline: 'none',
                                        textAlign: 'center'
                                    }),attrs:{"type":"text","onkeyup":"value=value.replace(/^(0+)|[^\\d]+/g,'')"},domProps:{"value":(size.number)},on:{"change":function($event){return _vm.changeSizeNum(index, size, size.number)},"blur":function($event){return _vm.focusNot(index)},"focus":_vm.selectInput,"input":function($event){if($event.target.composing)return;_vm.$set(size, "number", $event.target.value)}}})]):_vm._e(),(_vm.numType == '1' && _vm.componentType)?_c('div',{style:({
                                    height: _vm.numType == '1' ? '24px' : '50px',
                                    lineHeight: _vm.numType == '1' ? '24px' : '50px',
                                    borderTop: '1px solid #e6ebf5',
                                    background: _vm.getNumberItem(size, item) == '0' ? '#e6ebf5' : '#FFF'
                                })},[_vm._v(" "+_vm._s(_vm.getNumberItem(size, item) == '0' ? '' : _vm.getNumberItem(size, item))+" ")]):_vm._e()])])}),0),[_c('div',{staticStyle:{"display":"flex","flex-direction":"column"},style:({ width: _vm.componentType ? 94.3 + 'px' : 60 + 'px' })},[_c('div',{staticClass:"num-box",staticStyle:{"width":"100%"}},[_c('div',[_vm._v(_vm._s(item.usableQuantitySum))]),(_vm.numType == '1')?_c('div',{staticStyle:{"border-top":"1px solid #e6ebf5"}},[_vm._v(" "+_vm._s(item.numberSum)+" ")]):_vm._e()])]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column"},style:({ width: _vm.componentType ? 94.3 + 'px' : 60 + 'px' })},[_c('div',{staticStyle:{"height":"30px","line-height":"30px","width":"100%"}},[_vm._v(" "+_vm._s(item.thisWeekSellNum)+" ")])]),_c('div',{staticStyle:{"display":"flex","flex-direction":"column"},style:({ width: _vm.componentType ? 94.3 + 'px' : 60 + 'px' })},[_c('div',{staticStyle:{"height":"30px","line-height":"30px","width":"100%"}},[_vm._v(" "+_vm._s(item.lastWeekSellNum)+" ")])]),(!_vm.componentType)?_c('div',{staticStyle:{"display":"flex","flex-direction":"column"},style:({ width: 60 + 'px' })},[_c('div',{staticStyle:{"height":"30px","line-height":"30px","width":"100%","color":"red","cursor":"pointer"},on:{"click":function($event){return _vm.deleItems(index)}}},[_vm._v(" "+_vm._s('删除')+" ")])]):_vm._e()]],2)]})],2):_c('section',{ref:"body",staticClass:"table-body",style:({
            height: _vm.tabBodyHeight,
            maxHeight: _vm.tabBodyHeight,
            width: _vm.componentType ? 1250 + 'px' : _vm.tableWidth
        })},[[_c('div',{staticStyle:{"height":"50px","text-align":"center","line-height":"50px","font-size":"20px","color":"#888"}},[_vm._v(" 暂无数据 ")])]],2),(_vm.numType)?_c('section',{staticClass:"total-row",style:({ width: _vm.componentType ? 1250 + 'px' : _vm.tableWidth, height: 50 + 'px', lineHeight: 50 + 'px' })},[[_c('span',[_vm._v("总款数："),_c('span',{staticStyle:{"color":"red","font-size":"16px","font-weight":"bold","margin-left":"0px"}},[_vm._v(_vm._s(_vm.totalAmount)+"个")])]),(_vm.numType == '1')?_c('span',[_vm._v("补充总数："),_c('span',{staticStyle:{"color":"red","font-size":"16px","font-weight":"bold","margin-left":"0px"}},[_vm._v(_vm._s(_vm.goodsAllNum)+" 件")])]):_vm._e(),(_vm.numType == '2')?_c('span',[_vm._v("调出总数："),_c('span',{staticStyle:{"color":"red","font-size":"16px","font-weight":"bold","margin-left":"0px"}},[_vm._v(_vm._s(_vm.goodsAllNum)+" 件")])]):_vm._e()]],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }