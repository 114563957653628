<template>
    <section class="container">
        <stockTopMenu curPosition="店铺补货申请" windowId="storeReplenishment" module="money"> </stockTopMenu>
        <div class="box-big">
            <div class="header-box">
                <el-form inline style="width: 100%; height: 100%; padding: 25px 20px" :model="form" size="small">
                    <el-form-item label="货号" prop="goodsNo">
                        <erp-selector
                            type="goodsNo"
                            v-model="form.spuList"
                            ref="goodsItemNo"
                            modelKey="spu"
                            placeholder="请选择货号"
                            :multiple="true"
                        ></erp-selector>
                    </el-form-item>
                    <el-form-item label="创建时间">
                        <el-date-picker
                            type="daterange"
                            v-model="form.createTime"
                            format="yyyy-MM-dd"
                            value-format="yyyy-MM-dd"
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                        >
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="单号">
                        <el-input clearable placeholder="请输入单号,多个请用空格隔开" v-model="form.snList"></el-input>
                    </el-form-item>
                    <el-form-item class="el-item" style="margin-left: 50px">
                        <el-button @click="resetForm">重 置</el-button>
                        <div class="start-box" @click="search">
                            <span>查 询</span>
                        </div>
                        <div class="start-box" @click="addNew()">
                            <svg-icon class-name="size-icon" style="margin-right: 5px" icon-class="add" />
                            <span>新增补货申请</span>
                        </div>
                    </el-form-item>
                </el-form>
            </div>
            <div class="main-box">
                <div class="left-main">
                    <grid-manager :option="gridOptionss"></grid-manager>
                </div>
                <div class="right-main">
                    <div class="item-box">
                        <div class="items">
                            <h4>本店想补充款</h4>
                            <div class="bul-box"></div>
                            <div>
                                <PuhuoTable
                                    componentType="page"
                                    style="margin-left: 10px"
                                    numType="1"
                                    ref="puhuotab"
                                    sizeGroupType="2"
                                    sizeGroupWidth="620px"
                                    calcDivNum="4"
                                    outWidth="974"
                                    :headerLeft="headerLeft"
                                    :addedList="repairGoodsInfoList"
                                    :collectNumberList="repairGoodsInfoList"
                                    :isAdd="false"
                                    :headerRight="headerRight"
                                ></PuhuoTable>
                            </div>
                        </div>
                        <div class="items">
                            <h4>本店想调出款</h4>
                            <div class="bul-box"></div>
                            <div>
                                <PuhuoTable
                                    componentType="page"
                                    style="margin-left: 10px"
                                    numType="2"
                                    ref="puhuotab"
                                    sizeGroupType="2"
                                    sizeGroupWidth="620px"
                                    calcDivNum="4"
                                    outWidth="974"
                                    :headerLeft="headerLeft"
                                    :addedList="calloutGoodsInfoList"
                                    :isAdd="false"
                                    :headerRight="headerRight"
                                ></PuhuoTable>
                            </div>
                        </div>
                        <div class="items">
                            <h4>货品建议或备注</h4>
                            <div class="bul-box"></div>
                            <div style="height: 60px; width: 100%; margin-left: 10px">
                                <el-input
                                    type="textarea"
                                    :autosize="{ minRows: 2, maxRows: 4 }"
                                    :disabled="true"
                                    v-model="textarea2"
                                >
                                </el-input>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <storeDialog v-if="storeDialog" @close="closeAddDept"></storeDialog>
    </section>
</template>

<script>
import storeDialog from './components/storeDialog.vue'
import svgIcon from '@/components/SvgIcon/index'
import stockTopMenu from '../../components/stockTopMenu.vue'
import PuhuoTable from './components/PuhuoTable.vue'
import erpSelector from '@/components/ErpSelector/index'
import { erp2MarketReplenishmentQueryList, erp2MarketReplenishmentQueryDetail } from '@/libs/http/api.js'
export default {
    components: {
        stockTopMenu,
        PuhuoTable,
        erpSelector,
        svgIcon,
        storeDialog
    },
    data() {
        return {
            calloutGoodsInfoList: [],
            repairGoodsInfoList: [],
            storeDialog: false,
            form: {
                spuList: [],
                createTime: '',
                snList: ''
            },
            addedList: [],
            textarea2: '',
            headerLeft: [
                { key: 'idNo', name: '序号' },
                { key: 'invoicesId', name: '货号' },
                { key: 'goodsName', name: '货品名称' },
                { key: 'style', name: '款式分类三级' },
                { key: 'price', name: '零售价' },
                { key: 'size', name: '码段' }
            ],
            headerRight: [
                { key: 'allNum', name: '小计' },
                { key: 'thisNUm', name: '本周售数' },
                { key: 'upNum', name: '上周售数' }
            ],
            gridOptionss: {
                gridManagerName: 'storeReplenishment',
                firstLoading: true,
                isCombSorting: false,
                supportCheckbox: false,
                supportAutoOrder: false,
                useTrFocus: true,
                dataKey: 'items',
                totalsKey: 'totalItem',
                lineHeight: '30px',
                pageSize: 100,
                sizeData: [100, 200, 500, 1000],
                ajaxPageTemplate: `<div class="gm-toolbar" {{vm.keyName}}="{{vm.gridManagerName}}">
                    <!--每页显示条数-->
                    <div class="change-size">
                        {{ vm.pageSizeOptionTpl }}
                    </div>
                    <!--分页描述-->
                    <div class="toolbar-info page-info"></div>
                    <!--分页切换-->
                    <div class="pagination">
                        <!--上一页、首页区-->
                        <ul pagination-before>
                            <li class="previous-page">
                                {{ vm.previousPageText }}
                            </li>
                        </ul>
                        <!--下一页、尾页区-->
                        <ul pagination-after>
                            <li class="next-page">
                                {{ vm.nextPageText }}
                            </li>
                        </ul>
                    </div>
                </div>`,

                columnData: [
                    { key: 'sn', text: '单号', width: '200' },
                    { key: 'createTime', text: '创建时间' },
                    { key: 'repairNum', text: '补单总数', width: '80' },
                    { key: 'calloutNum', text: '调出总数', width: '80' }
                ],
                ajaxData: this.fetch, //数据
                supportAjaxPage: true,
                height: '79vh',
                rowClick: this.rowdbClick,
                deptList: []
                // userId: this.$cache.session.get(this.$macro.cache.KEY_USER_ID)
            }
        }
    },
    methods: {
        search() {
            GridManager.refreshGrid(this.gridOptionss.gridManagerName)
        },
        resetForm() {
            this.form.spuList = []
            this.form.snList = ''
            this.form.createTime = []
        },
        closeAddDept() {
            this.storeDialog = false
            GridManager.refreshGrid(this.gridOptionss.gridManagerName)
        },
        addNew() {
            this.storeDialog = true
        },
        fetch(params) {
            let reqData = {
                page: params.page,
                pageSize: params.pageSize
            }
            for (let key in this.form) {
                reqData[key] = this.form[key]
                if (key == 'createTime' && this.form.createTime.length) {
                    reqData['createStartTime'] = this.form[key][0] + ' 00:00:00'
                    reqData['createEndTime'] = this.form[key][1] + ' 23:59:59'
                }
                if (key == 'snList') {
                    if (this.form[key] == '') {
                        reqData['snList'] = []
                    } else {
                        reqData['snList'] = this.form[key].split(' ')
                    }
                }
            }
            return erp2MarketReplenishmentQueryList(reqData)
        },
        showDetail(row) {
            erp2MarketReplenishmentQueryDetail({
                id: row.id
            })
                .then((data) => {
                    this.calloutGoodsInfoList = data.calloutGoodsInfoList
                    this.repairGoodsInfoList = data.repairGoodsInfoList
                    this.textarea2 = row.remark
                })
                .catch((error) => {
                    this.$message({ type: 'error', message: error, duration: 2000, showClose: true })
                })
        },
        rowdbClick(row) {
			console.log(this.$cache.local.get('token'))
            this.textarea2 = row.remark
            this.showDetail(row)
        }
    }
}
</script>

<style scoped lang="scss">
.box-big {
    width: 100vw;
    height: calc(100vh - 60px);
    padding: 20px;
    display: flex;
    flex-direction: column;
    .header-box {
        width: 100%;
        height: 80px;
        background: #fff;
        margin-bottom: 20px;
        .el-form-item {
            margin-right: 40px;
            .start-box {
                margin-left: 15px;
                cursor: pointer;
                display: inline-block;
                padding: 0px 17px;
                font-size: 14px;
                background: #c6e7ff;
                margin-bottom: 13px;
                border-radius: 3px;
            }
            .start-box:last-child {
                padding: 0px 70px;
            }
            .start-box:active {
                color: #067cf2;
            }
        }
    }
    .main-box {
        flex: 1;
        display: flex;
        height: 100%;
        overflow: scroll;
        .left-main {
            width: 30%;
            height: 100%;
            padding: 13px;
            background: #fff;
            margin-right: 20px;
        }
        .right-main {
            flex: 1;
            background: #fff;
            .item-box {
                width: 100%;
                height: 100%;
                background: #fff;
                padding: 10px;
                overflow: scroll;
                .items {
                    position: relative;
                    margin-bottom: 60px;
                    h4 {
                        margin-top: 20px;
                        font-weight: 500;
                        line-height: 0px;
                        margin-left: 10px;
                        margin-bottom: 30px;
                    }
                    .bul-box {
                        width: 3px;
                        height: 20px;
                        background: #3da2ff;
                        position: absolute;
                        top: -10px;
                        left: -10px;
                    }
                }
            }
        }
    }
}
</style>
