<template>
    <section class="table">
        <section ref="head" class="table-header">
            <!-- 左侧动态数组 -->
            <div
                v-for="item in headerLeft"
                :key="item.key"
                :style="{
                    height: sizeGroup.length > 0 ? sizeGroup.length * 35 + 'px' : 35 + 'px',
                    lineHeight: sizeGroup.length > 0 ? sizeGroup.length * 37 + 'px' : 37 + 'px',
                    width: returnWidth(item)
                }"
            >
                <span>{{ item.name }}</span>
            </div>
            <!-- 表头尺码组列 通过sizeGroupType控制不同尺码组数据内容 -->
            <template v-if="valueGroupIdList.length <= 0">
                <section class="size-group" style="width: 440px; height: 30px">
                    <div v-for="item in 1" :key="item" style="text-align: center; line-height: 23px">尺码组</div>
                </section>
            </template>
            <template v-else>
                <!-- <section
                    class="size-group"
                    :style="{
                        height: sizeGroup.length > 0 ? sizeGroup.length * 60 + 'px' : 60 + 'px',
                        width: 440 + 'px',
                        lineHeight: sizeGroup.length > 0 ? sizeGroup.length * 25 + 'px' : 25 + 'px'
                        // paddingTop: sizeGroup.length > 0 ? sizeGroup.length * 15 + 'px' : ''
                    }"
                >
                    <div
                        v-for="item in sizeGroup"
                        :key="item.valueGroupId"
                        :class="[currentGroup == item.valueGroupId ? 'current-group' : '']"
                    >
                        <span v-for="(size, index) in item.valueInfoList" :key="index">{{ size.value }}</span>
                    </div>
                </section> -->
                <div
                    class="size-group"
                    style="width: 440px; display: flex; flex-direction: column; justify-content: center"
                >
                    <div
                        v-for="item in sizeGroup"
                        :key="item.valueGroupId"
                        :class="[currentGroup == item.valueGroupId ? 'current-group' : '']"
                    >
                        <span v-for="(size, index) in item.valueInfoList" :key="index">{{ size.value }}</span>
                    </div>
                </div>
            </template>

            <!-- 表头右侧列 -->
            <div
                v-for="item in headerRight"
                :key="item.key"
                :style="{
                    height: sizeGroup.length > 0 ? sizeGroup.length * 35 + 'px' : 35 + 'px',
                    lineHeight: sizeGroup.length > 0 ? sizeGroup.length * 37 + 'px' : 37 + 'px',
                    width: componentType ? 93.4 + 'px' : 60 + 'px'
                }"
            >
                <span>{{ item.name }}</span>
            </div>
        </section>

        <!-- 表格内容区 width: !isAdd ? tableWidth : ''-->
        <section
            v-if="dataList.length != 0"
            ref="body"
            class="table-body sizeInfo"
            :style="{
                width: componentType ? 1250 + 'px' : tableWidth
            }"
        >
            <template v-for="(item, index) in dataList">
                <div
                    :key="index"
                    @click="
                        currentGroup = item.valueGroupId
                        rowIndex = index
                    "
                    :class="['data-row', index == rowIndex ? 'current-row' : '']"
                >
                    <!-- 左侧动态数组对应列 -->
                    <template>
                        <div style="width: 55px">
                            <span>{{ index + 1 }}</span>
                        </div>
                        <div style="width: 104.5px">
                            <span>{{ item.goodsNo }}</span>
                        </div>
                        <div style="width: 104.5px">
                            <span>{{ item.goodsName }}</span>
                        </div>
                        <div style="width: 104.5px">
                            <span>{{ item.categoryName }}</span>
                        </div>
                        <div style="width: 55px">
                            <span>{{ item.retailPrice }}</span>
                        </div>
                        <div class="num-box" style="width: 104.5px">
                            <div>预计库存数</div>
                            <div v-if="numType == '1'" style="border-top: 1px solid #e6ebf5">想补单数</div>
                        </div>
                    </template>
                    <!-- 尺码组数据 -->
                    <section class="size-group" style="width: 440px">
                        <div v-for="(size, s) in getSizeInfoList(item, index)" :key="s">
                            <!--getSizeInfoList(item, index)-->
                            <div
                                class="num-box"
                                style="width: 35px; height: 50px; line-height: 50px; border-right: 1px solid #e6ebf5"
                                :style="{ background: getNumberItem(size, item) == '0' ? '#e6ebf5' : '#FFF' }"
                            >
                                <div
                                    :style="{
                                        height: numType == '1' ? '24px' : '50px',
                                        lineHeight: numType == '1' ? '24px' : '50px',
                                        background: getUsableQuantity(size, item) == '0' ? '#e6ebf5' : '#FFF'
                                    }"
                                >
                                    {{ getUsableQuantity(size, item) == '0' ? '' : getUsableQuantity(size, item) }}
                                </div>
                                <div
                                    :style="{
                                        height: numType == '1' ? '24px' : '50px',
                                        lineHeight: numType == '1' ? '24px' : '50px',
                                        borderTop: '1px solid #e6ebf5'
                                    }"
                                    v-if="
                                        numType == '1' &&
                                        !componentType &&
                                        (size.number == '0' || size.number > '0' || size.number == '')
                                    "
                                >
                                    <input
                                        type="text"
                                        onkeyup="value=value.replace(/^(0+)|[^\d]+/g,'')"
                                        v-model="size.number"
                                        :style="{
                                            border: 'none',
                                            width: '100%',
                                            height: '100%',
                                            background: size.number > 0 && !componentType ? '#43d268' : '#facd91',
                                            outline: 'none',
                                            textAlign: 'center'
                                        }"
                                        @change="changeSizeNum(index, size, size.number)"
                                        @blur="focusNot(index)"
                                        @focus="selectInput"
                                    />
                                </div>
                                <div
                                    :style="{
                                        height: numType == '1' ? '24px' : '50px',
                                        lineHeight: numType == '1' ? '24px' : '50px',
                                        borderTop: '1px solid #e6ebf5',
                                        background: getNumberItem(size, item) == '0' ? '#e6ebf5' : '#FFF'
                                    }"
                                    v-if="numType == '1' && componentType"
                                >
                                    {{ getNumberItem(size, item) == '0' ? '' : getNumberItem(size, item) }}
                                </div>
                            </div>
                        </div>
                    </section>

                    <template>
                        <div
                            style="display: flex; flex-direction: column"
                            :style="{ width: componentType ? 94.3 + 'px' : 60 + 'px' }"
                        >
                            <div class="num-box" style="width: 100%">
                                <div>{{ item.usableQuantitySum }}</div>
                                <div v-if="numType == '1'" style="border-top: 1px solid #e6ebf5">
                                    {{ item.numberSum }}
                                </div>
                            </div>
                        </div>
                        <div
                            style="display: flex; flex-direction: column"
                            :style="{ width: componentType ? 94.3 + 'px' : 60 + 'px' }"
                        >
                            <div style="height: 30px; line-height: 30px; width: 100%">
                                {{ item.thisWeekSellNum }}
                            </div>
                        </div>
                        <div
                            style="display: flex; flex-direction: column"
                            :style="{ width: componentType ? 94.3 + 'px' : 60 + 'px' }"
                        >
                            <div style="height: 30px; line-height: 30px; width: 100%">
                                {{ item.lastWeekSellNum }}
                            </div>
                        </div>
                        <div
                            v-if="!componentType"
                            style="display: flex; flex-direction: column"
                            :style="{ width: 60 + 'px' }"
                        >
                            <div
                                style="height: 30px; line-height: 30px; width: 100%; color: red; cursor: pointer"
                                @click="deleItems(index)"
                            >
                                {{ '删除' }}
                            </div>
                        </div>
                    </template>
                </div>
            </template>
        </section>
        <section
            v-else
            ref="body"
            class="table-body"
            :style="{
                height: tabBodyHeight,
                maxHeight: tabBodyHeight,
                width: componentType ? 1250 + 'px' : tableWidth
            }"
        >
            <template>
                <div style="height: 50px; text-align: center; line-height: 50px; font-size: 20px; color: #888">
                    暂无数据
                </div>
            </template>
        </section>

        <!-- 表尾合计行 -->
        <section
            v-if="numType"
            class="total-row"
            :style="{ width: componentType ? 1250 + 'px' : tableWidth, height: 50 + 'px', lineHeight: 50 + 'px' }"
        >
            <template>
                <span
                    >总款数：<span style="color: red; font-size: 16px; font-weight: bold; margin-left: 0px"
                        >{{ totalAmount }}个</span
                    ></span
                >
                <span v-if="numType == '1'"
                    >补充总数：<span style="color: red; font-size: 16px; font-weight: bold; margin-left: 0px"
                        >{{ goodsAllNum }} 件</span
                    ></span
                >
                <span v-if="numType == '2'"
                    >调出总数：<span style="color: red; font-size: 16px; font-weight: bold; margin-left: 0px"
                        >{{ goodsAllNum }} 件</span
                    ></span
                >
            </template>
        </section>
    </section>
</template>
<script>
import { erpQuerySizeInfo } from '@/libs/http/api.js'
export default {
    name: 'puhuoTable',
    props: {
        componentType: {
            //表格尺码组左侧数据
            type: String,
            default: () => null
        },
        numType: {
            //表格尺码组左侧数据
            type: String,
            default: () => '1'
        },
        headerLeft: {
            //表格尺码组左侧数据
            type: Array,
            default: () => []
        },
        headerRight: {
            //表格尺码组右侧数据
            type: Array,
            default: () => []
        },
        addedList: {
            //表格数据
            type: Array,
            default: () => []
        },
        stockType: {
            //库存类型
            type: String,
            default: '0'
        },
        tabBodyHeight: {
            //表格高度
            type: String,
            default: 'auto'
        },
        isAdd: {
            //区分新增和详情
            type: Boolean,
            default: true
        },
        sizeGroupType: {
            //尺码组类型，控制不同显示样式
            type: String,
            default: '1'
        },
        sizeGroupWidth: {
            //尺码组宽度
            type: String,
            default: '440px'
        },
        collectNumberList: {
            //尺码列汇总
            type: Array,
            default: () => []
        },
        scheduleState: {
            //控制各种数量是否显示
            type: String,
            default: '1'
        },
        searchInNo: {
            //当前操作的是哪个条码
            type: String,
            default: ''
        },
        isShowRow: {
            //控制这行是否显示，sizeGroupType = 4
            type: Boolean,
            default: false
        },
        calcDivNum: {
            //设置自动宽的div个数
            type: String,
            default: '5'
        },
        outWidth: {
            //表格总宽度
            type: String,
            default: '1100'
        },
        isAllowNegativeStocks: {
            //判断店铺是否可负库存出货
            type: String,
            default: 'N'
        },
        takeGoodsNum: {
            type: String,
            default: '0'
        },
        invoicesData: {
            type: String,
            default: () => ''
        },
        type: {
            type: String,
            default: () => ''
        }
    },
    emites: ['updateCheckNumber'],
    data() {
        return {
            goodsAllNum: '0',
            tableWidth: '',
            rowIndex: null,
            currentGroup: '',
            sizeGroup: [],
            dataList: [],
            curSizeVal: '',
            curPriceVal: '',
            phNumber: true,
            chNumber: false,
            chdNumber: false,
            rkNumber: false,
            rkdNumber: false,
            supplierType: false,
            // totalObj: {
            //     sellPriceTotal: 0, //零售总价
            //     distriPriceTotal: 0, //铺货总价
            //     distriNumTotal: 0, //铺货总数量
            //     chuhNumTotal: 0, //出货总数量
            //     chuhdNumTotal: 0, //出货差异总数量
            //     rukuNumTotal: 0, //入库总数量
            //     rukudNumTotal: 0, //入库差异总数量
            //     ReceivedTotal: 0, //已收总数量
            //     noReceivedTotal: 0, //未收总数量
            //     adjustTotal: 0, //调整总件数
            //     tackNum: 0, //已提数
            //     notackNum: 0, //未提数
            //     intoNum: 0, //已提数
            //     returnNum: 0, //已提数
            //     returnPrice: 0 //已提数
            // },
            totalNum: 0,
            valueGroupIdList: [],
            bodyScroll: false, //表格内容是否滚动

            curChangePrice: null, //新增调价时需要
            dialogVisible: false,
            form: {
                retailPrice: ''
            },
            checkHistoryNumber: 0
        }
    },
    watch: {
        numType() {
            this.dataList = this.addedList
            if (this.numType == '1') {
                if (this.addedList.length <= 5) {
                    this.$emit('bigNot', true)
                } else {
                    this.$emit('bigNot', false)
                }
            } else if (this.numType == '2') {
                if (this.addedList.length <= 5) {
                    this.$emit('bigNot2', true)
                } else {
                    this.$emit('bigNot2', false)
                }
            }
        },
        addedList() {
            if (this.addedList.length <= 5) {
                this.dataList = this.addedList
                this.$emit('getNum')
                this.editDataHandel()
            } else {
                if (this.numType == '1') {
                    this.$emit('bigNot', false)
                } else {
                    this.$emit('bigNot2', false)
                }
            }
            if (this.numType == '2') {
                this.quantityNum(2)
            } else if (this.numType == '1') {
                this.quantityNum(1)
            }
        },
        collectNumberList() {
            this.totalNum = this.collectNumberList.reduce((c, R) => c + Number(R), 0)
        },
        isShowRow() {
            this.dataList = []
            this.editDataHandel()
        },
        dataList() {
            this.valueGroupIdList = []
            this.dataList.forEach((item) => {
                if (item.valueGroupId) {
                    this.valueGroupIdList.push(item.valueGroupId)
                }
            })
            if (this.valueGroupIdList.length) {
                this.fetchSizeInfo()
            } else {
                this.sizeGroup = []
            }
        }
    },
    computed: {
        //根据不同状态返回不同数据进行渲染
        getNum() {
            const a = this.takeGoodsNum || this.totalObj.distriPriceTotal
            if (this.type == '1') {
                return this.invoicesData
            } else if (this.type == '2') {
                return a
            } else {
                return this.totalObj.distriNumTotal
            }
        },
        //总款数
        totalAmount() {
            let list = []
            this.dataList.forEach((item) => {
                if (!list.includes(item.spuInfoId)) {
                    list.push(item.spuInfoId)
                }
            })
            return list.length
        }
    },
    created() {
        this.dataList = this.addedList
        // this.supplierType = this.$cache.session.get(this.$macro.cache.KEY_SUPPLIER_ID) ? true : false
        this.setTableWidth()
    },
    methods: {
        selectInput(el) {
            el.target.select()
        },
        fetchSizeInfo() {
            erpQuerySizeInfo({ valueGroupIds: this.valueGroupIdList })
                .then((data) => {
                    if (data && data.length) {
                        this.sizeGroup = data
                    }
                })
                .catch((err) => {
                    this.$message({
                        type: 'error',
                        message: err,
                        duration: 2000,
                        showClose: true
                    })
                })
        },
        focusNot(index) {},
        getSizeList(item) {
            const sizeList = Array.from(
                item.sizeInfoList.reduce((map, obj) => map.set(obj.value, obj), new Map()).values()
            )
            return sizeList
        },
        changeSizeNum(index, size, number) {
            this.dataList[index].sizeInfoList.forEach((a) => {
                if (a.value == size.value) {
                    a.number = Number(number.replace(/^ +/, '')) || '0'
                }
            })
            if (!this.componentType) {
                let inNumList = []
                const array = this.dataList[index].sizeInfoList.map((item) => {
                    if (item.number > 0) {
                        return {
                            num: item.number
                        }
                    }
                })
                array.forEach((y) => {
                    if (y) {
                        inNumList.push(y)
                    }
                })
                this.dataList[index].numberSum = 0
                for (let i = 0; i < inNumList.length; i++) {
                    this.dataList[index].numberSum = Number(this.dataList[index].numberSum) + Number(inNumList[i].num)
                }

                let allInNumList = []
                const allArray = this.dataList.map((item) => {
                    if (item.numberSum != 0) {
                        return {
                            num: item.numberSum
                        }
                    }
                })
                allArray.forEach((y) => {
                    if (y) {
                        allInNumList.push(y)
                    }
                })
                this.goodsAllNum = 0
                for (let i = 0; i < allInNumList.length; i++) {
                    this.goodsAllNum = Number(this.goodsAllNum) + Number(allInNumList[i].num)
                }
            }
            this.$emit('getNum')
        },
        quantityNum(type) {
            let allInNumList = []
            if (type == '2') {
                const allArray = this.dataList.map((item) => {
                    if (item.usableQuantitySum != 0) {
                        return {
                            num: item.usableQuantitySum
                        }
                    }
                })
                allArray.forEach((y) => {
                    if (y) {
                        allInNumList.push(y)
                    }
                })
                this.goodsAllNum = 0
                for (let i = 0; i < allInNumList.length; i++) {
                    this.goodsAllNum = Number(this.goodsAllNum) + Number(allInNumList[i].num)
                }
            } else {
                const allArray = this.dataList.map((item) => {
                    if (item.numberSum > 0) {
                        return {
                            num: item.numberSum
                        }
                    }
                })
                allArray.forEach((y) => {
                    if (y) {
                        allInNumList.push(y)
                    }
                })
                this.goodsAllNum = 0
                for (let i = 0; i < allInNumList.length; i++) {
                    this.goodsAllNum = Number(this.goodsAllNum) + Number(allInNumList[i].num)
                }
            }
        },
        deleItems(index) {
            this.$confirm('确认删除此货品？', '提示')
                .then(() => {
                    const deleData = {
                        index: index,
                        type: this.numType,
                        boolean: true
                    }
                    this.$emit('deleItems', deleData)
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500
                    })
                })
                .catch(() => {})
        },
        getUsableQuantity(size, item) {
            let itemSize = '0'
            item.sizeInfoList.forEach((i) => {
                if (size.value == i.value) {
                    itemSize = i.usableQuantity
                }
            })
            return itemSize
        },
        getNumberItem(size, item) {
            let itemSize = '0'
            item.sizeInfoList.forEach((i) => {
                if (size.value == i.value) {
                    itemSize = i.number
                }
            })
            return itemSize
        },
        getGroupSizeInfoList(item) {
            let list = []
            this.sizeGroup.forEach((a) => {
                if (item.valueGroupId == a.valueGroupId) {
                    list = a.valueInfoList
                }
            })
            return list
        },
        getSizeInfoList(item, index) {
            const sizeList = Array.from(
                item.sizeInfoList.reduce((map, obj) => map.set(obj.value, obj), new Map()).values()
            )
            // for (let a = 0; a < sizeList.length; a++) {
            //     this.dataList[index].numberSum = this.dataList[index].numberSum + Number(sizeList[a].number)
            // }
            let list = []
            let array = []
            this.sizeGroup.forEach((a) => {
                if (a.valueGroupId == item.valueGroupId) {
                    list = a.valueInfoList
                }
            })
            array = item.sizeInfoList.concat(list)
            let d = []
            let hash = {}
            d = array.reduce((item, next) => {
                hash[next.value] ? '' : (hash[next.value] = true && item.push(next))
                return item
            }, [])

            return this.sortKey(d, 'value')
        },
        sortKey(array, key) {
            return array.sort(function (a, b) {
                var x = a[key]
                var y = b[key]
                return x < y ? -1 : x > y ? 1 : 0
            })
        },
        returnWidth(item) {
            if (item.name == '款式分类三级' || item.name == '货品名称' || item.name == '货号' || item.name == '码段') {
                return 104.5 + 'px'
            } else {
                return 55 + 'px'
            }
        },
        setTableWidth() {
            this.$nextTick(() => {
                this.tableWidth = this.$refs.head.offsetWidth + 'px'
            })
        },
        editDataHandel() {
            const list = this.addedList
            list.map((item, index) => {
                // 设置唯一标识字段用于去重
                if (this.addedList) {
                    let soleLabel = item.spuInfoId + item.skuInfoId + item.barCode + item.value
                    this.$set(item, 'soleLabel', soleLabel)
                }
            })
            this.dataList = this.uniqueFunc(this.dataList.concat(list), 'soleLabel')
        },
        uniqueFunc(arr, uniId) {
            const res = new Map()
            return arr.filter((item) => !res.has(item[uniId]) && res.set(item[uniId], 1))
        },
        delRow(index) {
            this.$confirm('是否删除该条记录', '提示')
                .then(() => {
                    this.dataList.splice(index, 1)
                    this.$message({
                        message: '操作成功',
                        type: 'success',
                        duration: 1500
                    })
                })
                .catch(() => {})
        },
        clearAll() {
            this.dataList = []
        }
    }
}
</script>

<style lang="scss" scoped>
.row-bg-red {
    background-color: #f8475f !important;
}
.row-bg-green {
    background-color: #43d268 !important;
}
.row-bg-blue {
    background-color: #067cf2 !important;
}
.row-bg-zero {
    background-color: #9332bf !important;
}
.row-bg-exceed {
    background-color: #ffccc7 !important;
}
.is-disabled {
    cursor: not-allowed;
    // background-color: #f5f7fa;
}
.footer-list {
    border-left: 1px solid #e6ebf5;

    div {
        box-sizing: inherit;
        // border-left: 1px solid #e6ebf5;
        border-right: 1px solid #e6ebf5;
        width: 35px;
    }
}
.time {
    width: 100px !important;
}

::v-deep .time .el-input__prefix {
    display: none !important;
}

::v-deep .time .el-el-input__suffix {
    display: none !important;
}

::v-deep .time .el-input__inner {
    width: 100px;
    padding: 5px 3px;
    border-radius: 0;
    background: #facd91;
    color: #000;

    &::placeholder {
        color: #fff !important;
    }
}

::v-deep .timeBg .el-input__inner {
    background: #fff;
}

.table {
    width: 100%;
    max-height: 96.6%;
    overflow: scroll;
    box-sizing: border-box;
    font-size: 12px;
    position: relative;
}

.table-header {
    background: #fafafa;
    font-size: 14px;
    border: 1px solid #e6ebf5;
    overflow: hidden;
    display: inline-flex;

    > div {
        text-align: center;
        border-right: 1px solid #e6ebf5;
        float: left;

        &:last-child {
            border: none;
        }
    }

    .size-group {
        border-right: 1px solid #e6ebf5;
        padding: 5px 0;
        float: left;

        div {
            text-align: left;

            span {
                border: 1px solid #e6ebf5;
                background: #fff;
                width: 35px;
                height: 26px;
                line-height: 26px;
                display: inline-block;
                text-align: center;
                border-left: none;
                border-bottom: none;

                &:first-child {
                    border-left: 1px solid #e6ebf5;
                }
            }

            &:last-child > span {
                border-bottom: 1px solid #e6ebf5;
            }
        }

        .current-group {
            span {
                background: rgb(194, 234, 252);
            }
        }
    }
}
.table-body {
    border: 1px solid #e6ebf5;
    border-top: none;
    overflow: auto;

    .current-row {
        background: #ebf7ff;

        div {
            background: #ebf7ff !important;
        }
    }

    .data-row {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #e6ebf5;
        overflow: hidden;
        height: 50px;
        > div {
            width: 55px;
            text-align: center;
            border-right: 1px solid #e6ebf5;
            display: flex;
            align-self: stretch;
            align-items: center;
            justify-content: center;
            > span {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }

            &:last-child {
                border: none;
            }
        }
        .num-box {
            display: flex;
            flex-direction: column;
            div {
                width: 100%;
                height: 24px;
                line-height: 24px;
            }
        }

        .last-cell-width {
            width: 74px;
        }

        .size-group {
            display: flex;
            text-align: center;
            border-right: 1px solid #e6ebf5;
        }

        .group-three {
            .group-label {
                width: 90px;

                div {
                    height: 31px;
                    line-height: 31px;
                    border-bottom: 1px solid #e6ebf5;
                    border-right: 1px solid #e6ebf5;

                    &:last-child {
                        border-bottom: none;
                    }
                }
            }

            .group-value {
                div {
                    span {
                        display: inline-block;
                        width: 35px;
                        height: 31px;
                        line-height: 31px;
                        border-right: 1px solid #e6ebf5;
                        border-bottom: 1px solid #e6ebf5;
                    }

                    &:last-child span {
                        border-bottom: none;
                    }
                }
                .input-item {
                    border: none;
                    text-align: center;
                    display: inline-block;
                    width: 60px;
                    height: 31px;
                    line-height: 31px;
                    border-right: 1px solid #ccc;
                    border-bottom: 1px solid #ccc;
                    background-color: #facd91;
                }
            }
        }
    }
}

.total-row {
    height: 34px;
    line-height: 34px;
    display: flex;
    background: rgb(243, 244, 247);
    border: 1px solid #e6ebf5;
    border-top: none;
    position: sticky;
    bottom: 0;
    left: 0;

    span {
        margin-left: 20px;
    }
}

.table-body::-webkit-scrollbar {
    width: 6px !important;
    background: #eee;
}

.table-body {
    -ms-overflow-style: none;
}

.table-body {
    overflow: -moz-scrollbars-none;
}

.table-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(97, 184, 179, 0.1);
    background: #78b4b4;
}
</style>
<style>
.size-group .el-checkbox__label {
    padding-left: 5px;
}
</style>
